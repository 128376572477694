<ngb-accordion activeIds="waste-list">
    <ngb-panel id="waste-list">
        <ng-template ngbPanelHeader let-panel>
            <h5 translate>Departure waste delivery receipts</h5>
        </ng-template>
        <ng-template ngbPanelContent>
            <div class="row">
                <div class="col-3" *ngIf="model.notificationTypeId === 'D' && !shortNotification">
                    <app-control label='Did V/L discharge any Waste?' type="yesno" [isDisabled]="!canEdit || !editMode" [(ngModel)]="model.wasteDischarged" [entity]="model" property="wasteDischarged"></app-control>
                </div>
            </div>
            <div class="row">
                <div class="col">
                    <app-editable-grid #wasteReceiptGrid [data]="model.wastesDeparture" entityName="VesselNotificationWasteReceipt"
                        [editMode]="editMode" [canEdit]="canEdit" (rowAction)="updateSequenceNumber()">
                        <kendo-grid-column title="{{'Receipt Number' | translate}}" field="receiptNumber" [width]="130"></kendo-grid-column>
                        <kendo-grid-column title="{{'Provider name' | translate}}" field="providerName">
                            <ng-template kendoGridEditTemplate let-dataItem="dataItem">
                                <app-control [disableTooltip]="true" [(ngModel)]="dataItem.providerName" [entity]="dataItem" property="providerName"></app-control>
                            </ng-template>
                        </kendo-grid-column>
                        <kendo-grid-column title="{{'Delivery from' | translate}}" field="wasteDeliveryFrom" format="g">
                            <ng-template kendoGridEditTemplate let-dataItem="dataItem">
                                <app-control type="datetime" [time]="true" [disableTooltip]="true"
                                    [(ngModel)]="dataItem.wasteDeliveryFrom" [entity]="dataItem" property="wasteDeliveryFrom"></app-control>
                            </ng-template>
                        </kendo-grid-column>
                        <kendo-grid-column title="{{'Delivery to' | translate}}" field="wasteDeliveryTo" format="g">
                            <ng-template kendoGridEditTemplate let-dataItem="dataItem">
                                <app-control type="datetime" [time]="true" [disableTooltip]="true"
                                    [(ngModel)]="dataItem.wasteDeliveryTo" [entity]="dataItem" property="wasteDeliveryTo"></app-control>
                            </ng-template>
                        </kendo-grid-column>
                        <ng-template kendoGridDetailTemplate let-dataItem>
                            <div class="row">
                                <div class="col">
                                    <app-editable-grid [data]="dataItem.wasteItems" entityName="WasteItem" [editMode]="isEditing">
                                        <kendo-grid-column title="Waste Type" [width]="320">
                                            <ng-template kendoGridEditTemplate let-dataItem="dataItem">
                                                <app-control type="codelist" codelist="WasteType" [disableTooltip]="true"
                                                    [(ngModel)]="dataItem.wasteTypeId" [entity]="dataItem" property="wasteTypeId"></app-control>
                                            </ng-template>
                                            <ng-template kendoGridCellTemplate let-dataItem>
                                                {{dataItem.wasteTypeId | codelist:'WasteType' | async}}
                                            </ng-template>
                                        </kendo-grid-column>
                                        <kendo-grid-column title="{{'Description' | translate}}">
                                            <ng-template kendoGridEditTemplate let-dataItem="dataItem">
                                                <app-control [disableTooltip]="true" [(ngModel)]="dataItem.description" [entity]="dataItem" property="description"></app-control>
                                            </ng-template>
                                            <ng-template kendoGridCellTemplate let-dataItem>
                                                {{defaultDisplayValue(dataItem.description, 'string')}}
                                            </ng-template>
                                        </kendo-grid-column>
                                        <kendo-grid-column title="{{'Quantity of waste received (m3)' | translate}}" field="quantity" format="n3" [width]="220">
                                            <ng-template kendoGridEditTemplate let-dataItem="dataItem">
                                                <app-control type="number" format="n3" [decimals]="3"
                                                    [disableTooltip]="true" [(ngModel)]="dataItem.quantity" [entity]="dataItem" property="quantity"></app-control>
                                            </ng-template>
                                        </kendo-grid-column>
                                    </app-editable-grid>
                                </div>
                                <div class="col-4">
                                    <app-editable-grid [data]="dataItem.treatmentFacilityProviders" [editMode]="isEditing"
                                        entityName="VesselNotificationWasteReceiptTreatmentFacilityProvider">
                                        <kendo-grid-column title="{{'Treatment Facility Provider Name' | translate}}">
                                            <ng-template kendoGridEditTemplate let-dataItem="dataItem">
                                                <app-control [disableTooltip]="true"
                                                    [(ngModel)]="dataItem.treatmentFacilityProviderName" [entity]="dataItem" property="treatmentFacilityProviderName"></app-control>
                                            </ng-template>
                                            <ng-template kendoGridCellTemplate let-dataItem>
                                                {{ defaultDisplayValue(dataItem.treatmentFacilityProviderName, 'string') }}
                                            </ng-template>
                                        </kendo-grid-column>
                                    </app-editable-grid>
                                </div>
                            </div>
                        </ng-template>
                    </app-editable-grid>
                </div>
            </div>
        </ng-template>
    </ngb-panel>
</ngb-accordion>
<div class="mb-3"></div>
<app-vessel-notification-attachment [attachments]="model.attachments" [canEdit]="canEdit" [editMode]="editMode"
    title='Supporting Documents' [user]="user"></app-vessel-notification-attachment>
