import {
    AdministrationPermissions,
    CodeListPermissions,
    ConveyancePermissions,
    DocumentPermissions,
    EmsaPermissions,
    EurostatReportsPermissions,
    FaqPermissions,
    NoticeBoardPermissions,
    ParisMOUPermissions,
    ShippingManifestPermissions,
    VTSPermissions,
    VesselNotificationPermissions,
    VesselPermissions,
    VesselPermitPermissions,
    VesselSchedulePermissions,
    VesselShiftPermissions,
    VesselShortNotificationPermissions,
    VesselVisitPermissions
} from '@common/classes/permissions';
import { User } from '@common/models/User';
import { MenuItem } from '@common/services/navigation.service';

export const NAVIGATION: MenuItem[] = [
    {
        title: 'Dashboard',
        icon: 'tachometer-alt',
        children: [
            {
                title: 'Task Manager',
                icon: 'chart-pie',
                route: '/task-manager'
            },
            {
                title: 'General Overview',
                icon: 'chart-pie',
                route: '/home',
                permissions: VesselNotificationPermissions.View.all
            },
            {
                title: 'Info',
                icon: 'chart-pie',
                route: '/home',
                permissions: VesselNotificationPermissions.View.all
            }
        ]
    },
    {
        title: 'Notice board',
        class: (user: User) => user.hasAnyNotifications ? 'text-danger' : null,
        icon: (user: User) => user.hasAnyNotifications ? 'exclamation-circle' : 'file-alt',
        permissions: NoticeBoardPermissions,
        children: [
            {
                title: 'Create new notice',
                icon: 'file-alt',
                route: '/notification/create',
                permissions: NoticeBoardPermissions.Action.create
            },
            {
                title: 'Notice list',
                icon: 'file-alt',
                route: '/notification/list',
                permissions: NoticeBoardPermissions.View.list
            },
            {
                title: 'Notice view',
                icon: 'file-alt',
                route: '/notification/userlist'
            }
        ]
    },
    {
        title: 'Chat Notes',
        icon: 'comments',
        permissions: [
            NoticeBoardPermissions
        ],
        route: '/chat-note/list'
    },
    {
        title: 'Vessel Registry',
        icon: 'ship',
        permissions: [VesselPermissions, ParisMOUPermissions],
        children: [
            {
                title: 'List',
                icon: 'file-alt',
                route: '/vessels',
                permissions: VesselPermissions.View.all
            },
            {
                title: 'Paris MOU',
                icon: 'list',
                route: '/paris-mou',
                permissions: ParisMOUPermissions.View.list
            },
            {
                title: 'Detained List',
                icon: 'ship',
                route: '/detained-vessels',
                permissions: VesselPermissions.View.detainedVesselList
            },
        ]
    },
    {
        title: 'Vessel Notifications',
        icon: 'ship',
        getNumber: (user: User) => user?.data?.totalWaitingVesselNotifications + user?.data?.totalWaitingVesselShifts,
        permissions: [VesselNotificationPermissions, VesselShiftPermissions],
        children: [
            {
                title: 'Create new notification',
                icon: 'file-alt',
                route: '/vessel-notification/create',
                permissions: VesselNotificationPermissions.Action.create
            },
            {
                title: 'Vessel notification list',
                icon: 'file-alt',
                route: '/vessel-notification/list',
                getNumber: (user: User) => user?.data?.totalWaitingVesselNotifications,
                permissions: VesselNotificationPermissions.View
            },
            {
                title: 'Vessel shiftings list',
                icon: 'file-alt',
                route: '/vessel-shift/list',
                getNumber: (user: User) => user?.data?.totalWaitingVesselShifts,
                permissions: VesselShiftPermissions.View
            }
        ]
    },
    {
        title: 'Short Notifications',
        icon: 'ship',
        getNumber: (user: User) => user?.data?.totalWaitingVesselShortNotifications,
        permissions: VesselShortNotificationPermissions,
        children: [
            {
                title: 'Create new short notification',
                icon: 'file-alt',
                route: '/short-vessel-notification/create',
                permissions: VesselShortNotificationPermissions.Action.create
            },
            {
                title: 'Short notification list',
                icon: 'file-alt',
                route: '/short-vessel-notification/list',
                permissions: VesselShortNotificationPermissions.View
            }
        ]
    },
    {
        title: 'Vessel Visits',
        icon: 'ship',
        route: '/vessel-visit/list',
        permissions: [VesselVisitPermissions, VesselVisitPermissions.View]
    },
    {
        title: 'Vessel Schedule',
        icon: 'calendar',
        route: '/vessel-schedule/vessel-arrivals-departures',
        permissions: VesselSchedulePermissions
    },
    {
        title: 'Vessel Permits',
        icon: 'ship',
        getNumber: (user: User) => user?.data?.totalWaitingPermits,
        permissions: VesselPermitPermissions,
        children: [
            {
                title: 'Create new vessel permit',
                icon: 'ship',
                route: '/vessel-permit/create',
                permissions: VesselPermitPermissions.Action.create
            },
            {
                title: 'Vessel permits list',
                icon: 'ship',
                route: '/vessel-permit/list',
                permissions: VesselPermitPermissions.View
            }
        ]
    },
    {
        title: 'Conveyance',
        icon: 'file-alt',
        getNumber: (user: User) => user?.data?.totalWaitingConveyances,
        permissions: ConveyancePermissions,
        children: [
            {
                title: 'Create new conveyance',
                icon: 'file-alt',
                route: '/conveyance/create',
                permissions: ConveyancePermissions.Action.create
            },
            {
                title: 'Conveyance list',
                icon: 'file-alt',
                route: '/conveyance/list',
                permissions: ConveyancePermissions.View
            }
        ]
    },
    {
        title: 'Shipping Manifest',
        icon: 'chart-pie',
        permissions: [ShippingManifestPermissions],
        children: [
            {
                title: 'Shipping manifest list',
                icon: 'chart-pie',
                route: '/shipping-manifest/list',
                permissions: [ShippingManifestPermissions],
            },
            {
                title: 'Eurostat reports',
                icon: 'chart-pie',
                route: '/shipping-manifest/eurostat-reports',
                permissions: [EurostatReportsPermissions]
            },
        ]
    },
    {
        title: 'Messages',
        icon: 'inbox',
        permissions: [EmsaPermissions],
        children: [
            {
                title: 'EMSA',
                icon: 'envelope',
                route: 'messages/emsa-messages/list',
                permissions: [EmsaPermissions],
            },
            {
                title: 'Energy Consumption',
                icon: 'envelope',
                route: 'messages/energy-consumption-messages/list',
                permissions: []
            },
            {
                title: 'Edifact',
                icon: 'envelope',
                route: 'messages/edifact-messages/list',
                permissions: []
            }
        ]
    },
    {
        title: 'Vessel Traffic System',
        icon: 'tower-cell',
        permissions: VTSPermissions,
        children: [
            {
                title: 'Dashboard',
                icon: 'table-columns',
                route: '/vts/dashboard',
                permissions: VTSPermissions.View.all
            },
            {
                title: 'Incident Reports',
                icon: 'file-alt',
                route: '/vts/incident-reports',
                permissions: VTSPermissions.View.all
            },
            {
                title: 'VTS Logging',
                icon: 'tower-cell',
                route: '/vts/port-book',
                permissions: VTSPermissions.View.all
            },
            {
                title: 'VTS Administration',
                icon: 'users',
                route: '/vts/vts-administration',
                permissions: VTSPermissions.View.all
            },
            {
                title: 'VTS Vessels',
                icon: 'ship',
                route: '/vts/vts-vessel',
                permissions: VTSPermissions.View.all
            },
            {
                title: 'VTS Code Lists',
                icon: 'cog',
                route: '/vts/vts-codelist',
                permissions: VTSPermissions.View.all
            },
            {
                title: 'VTS History',
                icon: 'box-archive',
                route: '/vts/vts-history',
                permissions: VTSPermissions.View.all
            }
        ]
    },
    {
        title: 'Administration',
        icon: 'cog',
        permissions: [AdministrationPermissions, FaqPermissions, DocumentPermissions],
        children: [
            {
                title: 'Users',
                icon: 'users',
                route: '/administration/user',
                permissions: [AdministrationPermissions.User.viewAll, AdministrationPermissions.User.viewOwn]
            },
            {
                title: 'Organizations',
                icon: 'building',
                route: '/administration/organization',
                permissions: [AdministrationPermissions.Organization.viewAll, AdministrationPermissions.Organization.viewOwn]
            },
            {
                title: 'Roles',
                icon: 'user-plus',
                route: '/administration/role',
                permissions: [AdministrationPermissions.Role.viewAll, AdministrationPermissions.Role.viewOwn]
            },
            {
                title: 'FAQ Edit',
                icon: 'question',
                route: '/help/faq-edit',
                permissions: FaqPermissions
            },
            {
                title: 'Documents Edit',
                icon: 'file-alt',
                route: '/help/document-edit',
                permissions: DocumentPermissions
            },
        ]
    },
    {
        title: 'Code Lists',
        icon: 'file-alt',
        permissions: CodeListPermissions,
        children: [
            {
                title: 'Country',
                icon: 'file-alt',
                route: '/code-list/country',
                permissions: CodeListPermissions.Country.view
            },
            {
                title: 'Locodes',
                icon: 'file-alt',
                route: '/code-list/location',
                permissions: CodeListPermissions.Location.view
            },
            {
                title: 'Terminal Operator',
                icon: 'file-alt',
                route: '/code-list/terminal-operator',
                permissions: CodeListPermissions.TerminalOperator.view
            },
            {
                title: 'Berth',
                icon: 'file-alt',
                route: '/code-list/berth',
                permissions: CodeListPermissions.Berth.view
            },
            {
                title: 'Service Boat',
                icon: 'file-alt',
                route: '/code-list/service-boat',
                permissions: CodeListPermissions.ServiceBoat.view
            },
            {
                title: 'Launch Operator',
                icon: 'file-alt',
                route: '/code-list/launch-operator',
                permissions: CodeListPermissions.LaunchOperator.view
            },
            {
                title: 'Vessel Source',
                icon: 'file-alt',
                route: '/code-list/vessel-source',
                permissions: CodeListPermissions.VesselSource.view
            },
            {
                title: 'Vessel Type',
                icon: 'file-alt',
                route: '/code-list/vessel-type',
                permissions: CodeListPermissions.VesselType.view
            },
            {
                title: 'Vessel Permit Type',
                icon: 'file-alt',
                route: '/code-list/vessel-permit-type',
                permissions: CodeListPermissions.VesselPermitType.view
            },
            {
                title: 'Vessel Contact Medium',
                icon: 'file-alt',
                route: '/code-list/vessel-contact-medium',
                permissions: CodeListPermissions.VesselContactMedium.view
            },
            {
                title: 'Certificate Type',
                icon: 'file-alt',
                route: '/code-list/certificate-type',
                permissions: CodeListPermissions.CertificateType.view
            },
            // {
            //     title: 'Manifest Cargo Type',
            //     icon: 'file-alt',
            //     route: '/code-list/manifest-cargo-type',
            //     permissions: [
            //         CodeListPermissions.ManifestCargoType.view
            //     ]
            // },
            // {
            //     title: 'Commodity',
            //     icon: 'file-alt',
            //     route: '/code-list/commodity',
            //     permissions: [
            //         CodeListPermissions.Commodity.view
            //     ]
            // },
            // {
            //     title: 'MCA',
            //     icon: 'file-alt',
            //     route: '/code-list/mca',
            //     permissions: [
            //         CodeListPermissions.MCA.view
            //     ]
            // },
            {
                title: 'Vessel Action Organizations',
                icon: 'file-alt',
                route: '/code-list/vessel-action-entity',
                permissions: CodeListPermissions.VesselActionEntity.view
            },
            {
                title: 'Email Subscription',
                icon: 'envelope',
                route: '/code-list/email-subscription'
            }
        ]
    },
    {
        title: 'Audit Logs',
        icon: 'list-alt',
        permissions: [AdministrationPermissions],
        isVisible: (user: User) => user?.isSystemUser,
        route: '/audit-logs',
    },
    {
        title: 'Help',
        icon: 'question-circle',
        children: [
            {
                title: 'FAQ',
                icon: 'question',
                route: '/help/faq-list',
                permissions: FaqPermissions.View
            },
            {
                title: 'Documents',
                icon: 'file-alt',
                route: '/help/document-list',
                permissions: DocumentPermissions.View
            },
            {
                title: 'NMSW Manuals',
                icon: 'video',
                route: '/help/nmsw-manuals-view'
            },
            {
                title: 'VTS Manuals',
                icon: 'video',
                route: '/help/vts-manuals-view',
                permissions: VTSPermissions
            }
        ]
    }
];
